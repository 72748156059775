import React, { useState } from 'react';
import './style.css';
import Form from '../Form';
import Delete from '../delete.svg';

function AccordionItem({ title, passageiros, validationSchema, formData, handleChange, onRemove, chave, limite, isOpen, setIsOpen, setHabilitado }) {
  
    const viewAncora = limite > 1 ? limite - 1 : limite;

    return (
    <div className="accordion-item">
        {chave === viewAncora ? <div id="passageiros"></div> : <></>}
        <div className="accordion-header">
            <button className='button-transparent' onClick={() => onRemove(chave)}>
                <img
                    className="icon"
                    src={Delete}
                    alt="Place"
                    width={25}
                    height={25}
                />
            </button>
            <h2>{title}</h2>
            <div className='ellipse-icon' onClick={() => setIsOpen(!isOpen)}>
                <span className={`iconI ${isOpen ? 'open' : 'closed'}`}></span>
            </div>
        </div>
        {isOpen && 
            <div className="accordion-content">
                <Form titular={passageiros} validationSchema={validationSchema} formData={formData} handleChange={handleChange} setHabilitado={setHabilitado} />
            </div>
        }
    </div>
  );
}

function Accordion({ passageiros, validationSchema, formData, handleChange, isOpen, setIsOpen, setHabilitado }) {
    const [cards, setCards] = useState(passageiros)
    
    const handleDelete = (index) =>{
        
        const updatedCards = cards.filter(card => card.id !== index);
        setCards(updatedCards);
    }

    return (
        <div className="accordion">
        {cards.map((item, index) => (
            <div className="bg-card borda-card-cad borda-card-cad-acc rounded-xl" key={index}>
            <AccordionItem 
                key={item.id} 
                title={item.title} 
                passageiros={passageiros}  
                validationSchema={validationSchema} 
                formData={formData} 
                handleChange={handleChange} 
                onRemove={handleDelete} 
                chave={item.id} 
                limite={cards.length} 
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setHabilitado={setHabilitado}
            />
            </div>
        ))}
        </div>
    );
}

export default Accordion;
