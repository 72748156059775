import React, {useState} from 'react';
import AccordionForm from '../Accordion';

import './style.css';

export default function Passageiro({passageiros, validationSchema, formData, handleChange, isOpen, setIsOpen, setHabilitado}) {
  const [expanded, setExpanded] = useState(false);

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <AccordionForm 
      passageiros={passageiros} 
      validationSchema={validationSchema} 
      formData={formData} 
      handleChange={handleChange} 
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setHabilitado={setHabilitado}
    />
  );
}