// src/components/Home.tsx
"use client"
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import './style.css';
import LogoColor from '../LogoColor.svg';

export default function Card() {
  const navigate = useNavigate();
  const [buttonColor, setButtonColor] = useState('');

  const handleMouseOver = () => {
    setButtonColor('#146658');
  };
  
  const handleMouseOut = () => {
    setButtonColor('#1A8372');
  };

  const formik = useFormik({
    
    onSubmit: (values) => {

      navigate('/');
    },
  });

  return (
    <div className='container-final header-final body-final'>
        <div className="borda-card-final">
            <img
                className="imagem-centralizada"
                src={LogoColor}
                alt="Logo"
            />
            <h2 className='titulo-final'>Certificado emitido com sucesso!</h2>
            <p className='descricao-final'>Agora você pode viajar com mais segurança e tranquilidade </p>
            <p className='sub-descricao'>Faça o download do seu certificado aqui.</p>
            
            <div>
                <button type="submit" className='buton-finalizar'>
                    <svg width="24" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_7_22181)">
                        <path d="M29.5848 13.651C29.4718 13.6504 29.3598 13.6725 29.2552 13.7158C29.1507 13.7591 29.0557 13.8228 28.9758 13.9033C28.8959 13.9838 28.8326 14.0794 28.7896 14.1847C28.7466 14.29 28.7247 14.4028 28.7253 14.5166V22.3904C28.7242 23.419 28.318 24.4052 27.5957 25.1325C26.8735 25.8599 25.8943 26.269 24.8729 26.2701H6.01578C4.9944 26.269 4.01516 25.8599 3.29294 25.1325C2.57071 24.4052 2.16449 23.419 2.16342 22.3904V14.3885C2.16342 14.2748 2.14118 14.1622 2.09799 14.0572C2.05479 13.9522 1.99148 13.8567 1.91166 13.7764C1.83185 13.696 1.73709 13.6322 1.63281 13.5887C1.52852 13.5452 1.41675 13.5228 1.30388 13.5228C1.191 13.5228 1.07923 13.5452 0.974945 13.5887C0.87066 13.6322 0.775905 13.696 0.69609 13.7764C0.616274 13.8567 0.552961 13.9522 0.509765 14.0572C0.466569 14.1622 0.444336 14.2748 0.444336 14.3885V22.3904C0.445769 23.8781 1.03322 25.3044 2.07776 26.3563C3.1223 27.4083 4.53858 27.9999 6.01578 28.0013H24.8729C26.3501 27.9999 27.7664 27.4083 28.8109 26.3563C29.8555 25.3044 30.4429 23.8781 30.4443 22.3904V14.5166C30.4443 14.4029 30.4221 14.2904 30.3789 14.1853C30.3357 14.0803 30.2724 13.9849 30.1926 13.9045C30.1128 13.8241 30.018 13.7604 29.9137 13.7169C29.8094 13.6734 29.6977 13.651 29.5848 13.651Z" fill="#E224A9"/>
                        <path d="M14.8372 21.1268C14.9976 21.2889 15.215 21.381 15.4423 21.3831C15.5551 21.3835 15.6668 21.361 15.7707 21.317C15.8747 21.2729 15.9688 21.2082 16.0474 21.1268L21.5105 15.6249C21.6721 15.4622 21.7628 15.2416 21.7628 15.0115C21.7628 14.7814 21.6721 14.5607 21.5105 14.3981C21.349 14.2354 21.1299 14.144 20.9014 14.144C20.6729 14.144 20.4538 14.2354 20.2923 14.3981L16.3005 18.4249V0.865628C16.3005 0.636049 16.2099 0.415874 16.0487 0.253537C15.8875 0.0912002 15.6689 0 15.4409 0C15.213 0 14.9944 0.0912002 14.8332 0.253537C14.672 0.415874 14.5814 0.636049 14.5814 0.865628V18.4277L10.5829 14.4008C10.5029 14.3202 10.4079 14.2563 10.3034 14.2127C10.1989 14.1691 10.0868 14.1467 9.97372 14.1467C9.86059 14.1467 9.74859 14.1691 9.64407 14.2127C9.53956 14.2563 9.44459 14.3202 9.3646 14.4008C9.28461 14.4813 9.22115 14.577 9.17786 14.6822C9.13457 14.7875 9.1123 14.9003 9.1123 15.0142C9.1123 15.1281 9.13457 15.241 9.17786 15.3462C9.22115 15.4515 9.28461 15.5471 9.3646 15.6277L14.8372 21.1268Z" fill="#E224A9"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_7_22181">
                        <rect width="30" height="28" fill="white" transform="translate(0.444336)"/>
                        </clipPath>
                        </defs>
                    </svg>
                    &nbsp;
                    &nbsp;
                    <span className='text-button-final'>Baixar certificado</span>
                </button>
                <div className="contato">
                    <label>Em instantes você também receberá o certificado no seu e-mail! </label>
                </div>
            </div>
        </div>
    </div>
  );
};