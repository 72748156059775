"use client"

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { TextField, Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import MaskedInput from 'react-text-mask';
import Destino from './Destino';

import './style.css';


const CpfMask = React.forwardRef((props, ref) => {
  const { inputRef, ...other } = props;

  const cpfMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ];

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        if (inputRef) {
          inputRef({ inputElement: ref });
        }
      }}
      mask={cpfMask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
});


export default function FormClient({titular, validationSchema, formData, handleChange, setIsOpen, setHabilitado}) {
  const navigate = useNavigate();
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [sexo, setSexo] = useState('');

  const [selectedCountry, setSelectedCountry] = useState('');
  
  const [quantidadeDestino, setQuantidadeDestino] = useState(0);
  const [selectedIcon, setSelectedIcon] = useState('');

  const [buttonColor, setButtonColor] = useState('');
  const [buttonCotnColor, setButtonCotnColor] = useState('');
  const [componentes, setComponentes] = useState([]);
  const [isMultiviagens, setIsMultiviagens] = useState();

  const multiviagens = localStorage.getItem("multiviagens");
  
  useEffect(() => {
    setIsMultiviagens(multiviagens);
  }, [])

  const handleChangeSexo = (event) => {
    setSexo(event.target.value);
  };
  
  const handleChangeCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleMouseOver = () => {
    setButtonColor('#1d1d1d');
  };

  const handleMouseOut = () => {
    setButtonColor('#000');
  };

  const continueMouseOver = () => {
    setButtonCotnColor('#b81482');
  };
  
  const continueMouseOut = () => {
    setButtonCotnColor('#E224A9');
  };

  const formik = useFormik({
    initialValues: {
      cpf: titular[0].documento,
      nome: titular[0].nome + " " + titular[0].sobrenome,
      dtNascimento: titular[0].dataNascimento,
      email: titular[0].email,
      cep: titular[0].cep,
      endereco: titular[0].addreess,
      numero: titular[0].numero,
      cidade: titular[0].cidade,
      uf: titular[0].uf
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setCpf(values.cpf);
      setNome(values.nome);
      setNascimento(values.nascimento);

      navigate('/emitir-certificado');
    },
  });

  const handleDelete = (index) => {
      
    const updatedDestino = componentes.filter(dest => dest.key !== index);
    setComponentes(updatedDestino);
  }

  const adicionarComponente = () => {
    const qtnd = quantidadeDestino + 1;
  
    setQuantidadeDestino(qtnd);
    setComponentes([...componentes, 
      <Destino 
        quantidade={quantidadeDestino}
        clienteId={titular[0].clienteId}
        formData={formData}
        titular={titular[0]}
        handleChange={handleChange}
        onRemove={handleDelete}
        setHabilitado={setHabilitado}
      />]);
  };

  const [messagemEmail, setMessagemEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);

  document.addEventListener('mousedown', function(event) {
    const email = document.getElementById('email').value;
    const confirmar_email = document.getElementById('confirmar_email').value;
    const telefone = document.getElementById('fone').value;
    const endereco = document.getElementById('endereco').value;
    const numero = document.getElementById('numero').value;
    const cidade = document.getElementById('cidade').value;
    // const destino = localStorage.getItem('destino_'+titular[0].key);
    // const inicioVigencia = localStorage.getItem('inicioVigencia');
    // const fimVigencia = localStorage.getItem('fimVigencia');
  
    if(email !== confirmar_email && email !== undefined && confirmar_email !== '') {
      setErrorEmail(true);
      setMessagemEmail('Email e a sua confirmação não conferem');
      setHabilitado(true)
      return
    } else {
      setErrorEmail(false);
      setHabilitado(false);
    }

    if (email === '' || telefone === '' ||
      endereco === '' || numero === '' || cidade === ''
      ) {
        setHabilitado(true);
    } else {
      setHabilitado(false);
    }
  });

  const handleContinuar = (id) => { 
    if (titular[0].key === 0) {
      setIsOpen(true)
    }

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    adicionarComponente();
  }, []);

  return (
    <>
      {titular.map((item) => (
        <>
        <text className='sessao'>{item.dados}</text>
        <div key={item.key} className="borda-form">
            <div>
              <div className="margin-fields">
                <TextField
                  fullWidth
                  id='documento'
                  variant="outlined"
                  name='documento'
                  label="CPF"
                  disabled={true}
                  InputProps={{
                    inputComponent: CpfMask,
                  }}
                  value={formData[item.key]['documento']}/>
              </div>
              <div className="margin-fields">
                <TextField
                  fullWidth
                  id='nome'
                  variant="outlined"
                  name='nome'
                  disabled={true}
                  label="Nome completo"
                  value={formData[item.key]['nome']}/>
              </div>
              <div className="margin-fields">
                <label htmlFor="">Sexo</label>
                <div className="grid-two-equal-columns">
                  <RadioGroup value={sexo !== '' ? sexo : formData[item.key]['sexo'] } id={'sexo'} name={'sexo'} onChange={(event) => handleChange(event, item.key)}>
                    <div className="grid-two-equal-columns">
                      <FormControlLabel
                        value="M"
                        control={<Radio />}
                        label="Masculino" />
                      <FormControlLabel
                        value="F"
                        control={<Radio />}
                        label="Feminino" />
                    </div>
                  </RadioGroup>
                  <TextField
                    fullWidth
                    id="dtNascimento"
                    variant="outlined"
                    disabled={true}
                    name="dtNascimento"
                    label="Data de nascimento"
                    value={formData[item.key][`dtNascimento`]}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.nascimento && Boolean(formik.errors.nascimento)}
                    helperText={formik.touched.nascimento && formik.errors.nascimento} />
                </div>
              </div>
            </div>
            <div>
              <div className="linha-horizontal"></div>
            </div>
            <text className='sessao interna'>Contato</text>
            <div className="margin-fields">
              <TextField
                fullWidth
                id='email'
                variant="outlined"
                name='email'
                label="E-mail"
                value={formData[item.key]['email']}
                onChange={(event) => handleChange(event, item.key)}
                onBlur={formik.handleBlur}
                //error={formik.touched.email && Boolean(formik.errors.email)}
                //helperText={formik.touched.email && formik.errors.email} 
                />
            </div>
            <div className="margin-fields">
              <TextField
                fullWidth
                id='confirmar_email'
                variant="outlined"
                name='confirmar_email'
                label="Confirmar e-mail"
                value={formData[item.key]['confirmar_email']}
                onChange={(event) => handleChange(event, item.key)}
                onBlur={formik.handleBlur}
                // error={formik.touched.confirmar_email && Boolean(formik.errors.confirmar_email)}
                // helperText={formik.touched.confirmar_email && formik.errors.confirmar_email} 
                />
            </div>
            {errorEmail && (
           <div className="" style={{marginTop: '33px', marginBottom: '-30px'}}>
                <h4 className="alerta-error">{messagemEmail}</h4>
              </div>
            )}
            <div className="margin-fields">
              <TextField
                fullWidth
                id='fone'
                variant="outlined"
                name='fone'
                label="Telefone"
                value={formData[item.key]['fone']}
                onChange={(event) => handleChange(event, item.key)}
                onBlur={formik.handleBlur}
                // error={formik.touched.telefone && Boolean(formik.errors.telefone)}
                // helperText={formik.touched.telefone && formik.errors.telefone} 
                />
            </div>
            <div>
              <div className="linha-horizontal"></div>
            </div>
            <text className='sessao interna'>Endereço</text>
            <div className="margin-fields">
              <TextField
                fullWidth
                id='cep'
                variant="outlined"
                name='cep'
                label="CEP"
                value={formData[item.key]['cep']}
                onChange={(event) => handleChange(event, item.key)}
                onBlur={formik.handleBlur}
                // error={formik.touched.cep && Boolean(formik.errors.cep)}
                // helperText={formik.touched.cep && formik.errors.cep} 
                />
            </div>
            <div className="margin-fields">
              <TextField
                fullWidth
                id='endereco'
                variant="outlined"
                name='endereco'
                label="Lougradouro"
                value={formData[item.key]['endereco']}
                onChange={(event) => handleChange(event, item.key)}
                onBlur={formik.handleBlur}
                // error={formik.touched.endereco && Boolean(formik.errors.endereco)}
                // helperText={formik.touched.endereco && formik.errors.endereco} 
                />
            </div>
            <div className="margin-fields grid-two-columns-left">
              <TextField
                fullWidth
                id='numero'
                variant="outlined"
                style={{ marginBottom: "20px" }} 
                name='numero'
                label="Número"
                value={formData[item.key]['numero']}
                onChange={(event) => handleChange(event, item.key)}
                onBlur={formik.handleBlur}
                // error={formik.touched.numero && Boolean(formik.errors.numero)}
                // helperText={formik.touched.numero && formik.errors.numero} 
                />
              <TextField
                fullWidth
                id='bairro'
                variant="outlined"
                name='bairro'
                label="Bairro"
                value={formData[item.key]['bairro']}
                onChange={(event) => handleChange(event, item.key)}
                onBlur={formik.handleBlur}
                // error={formik.touched.bairro && Boolean(formik.errors.bairro)}
                // helperText={formik.touched.bairro && formik.errors.bairro} 
                />
            </div>
            <div className="margin-fields grid-two-columns-right">
              <TextField
                fullWidth
                id='cidade'
                variant="outlined"
                style={{ marginBottom: "20px" }} 
                name='cidade'
                label="Cidade"
                value={formData[item.key]['cidade']}
                onChange={(event) => handleChange(event, item.key)}
                onBlur={formik.handleBlur}
                error={formik.touched.cidade && Boolean(formik.errors.cidade)}
                helperText={formik.touched.cidade && formik.errors.cidade} />
              <TextField
                fullWidth
                id='uf'
                variant="outlined"
                name='uf'
                label="Estado"
                value={formData[item.key]['uf']}
                onChange={(event) => handleChange(event, item.key)}
                onBlur={formik.handleBlur}
                error={formik.touched.uf && Boolean(formik.errors.uf)}
                helperText={formik.touched.uf && formik.errors.uf} />
            </div>


            {/* <Destino /> */}
            {componentes.map((componente, index) => (
              // Renderiza cada componente da lista
              <div key={index}>{componente}</div>
            ))}

            <div className="button-sessao">
            {isMultiviagens !== '0' && (
              <button
                onClick={adicionarComponente}
                style={{ backgroundColor: buttonColor }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                className='buton-add-destino botao centralizar'>
                <p className='text-button'>Adicionar mais um destino</p>
              </button>
              )}
              {titular[0].dependente > 0 && (
                <button
                  style={{ backgroundColor: buttonCotnColor }}
                  onMouseOver={continueMouseOver}
                  onMouseOut={continueMouseOut}
                  type='button'
                  className='buton-continuar botao centralizar' onClick={() => handleContinuar('passageiros')}>
                  <p className='text-button'>Continuar</p>
                </button>
              )}
            </div>
        </div>
        </>
        ))}
    </>
  );
};