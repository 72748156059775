import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select/SelectInput';

export default function ListDestino(props)  {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
          try {
            const response = await fetch('https://restcountries.com/v3.1/all?lang=pt');
            const data = await response.json();
            const paisesOrdenados = data.sort((a, b) => {
                const nomeA = a.translations.por.common.toUpperCase();
                const nomeB = b.translations.por.common.toUpperCase();
              
                if (nomeA < nomeB) {
                  return -1;
                }
                if (nomeA > nomeB) {
                  return 1;
                }

                return 0;
              });

            setCountries(paisesOrdenados);
          } catch (error) {
            console.error('Error fetching countries:', error);
          }
        };
    
        fetchCountries();
    }, []);

    return(
        <div>
            <Select
                id="selectOption"
                value={props.dataFromDes.destino}
                onChange={props.dataFrom.handleSelectChange}
                style={{ border: 'none' }}
            >
                <MenuItem value="">SELECIONE PARA ONDE VAI</MenuItem>
                <MenuItem>
                    <span className="linha-select"></span>
                </MenuItem>
                {countries.map((country) => (
                    <MenuItem value={country.translations.por.common}>{country.translations.por.common}</MenuItem>
                ))}
            </Select>
        </div>
    )
}