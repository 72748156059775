"use client"

import React, { useState } from 'react';
import Modal from 'react-modal';
import Calendar from '../calendar.svg';
import Place from '../place.svg';
import Delete from '../delete.svg';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ptBR from 'date-fns/locale/pt-BR';

import ListDestino from './ListDestino';

import './style.css';
import './style_modal.css';

export default function Destino({quantidade, clienteId, formData, titular, handleChange, onRemove, setHabilitado}) {

    const vigenciaMaxima = localStorage.getItem('vigencia');
    const [isModalDestino, setIsModalDestino] = useState(false);
    const [errorVigencia, setErrorVigencia] = useState(false);
    const [messagemVigencia, setMessagemVigencia] = useState('');
    
    const [isCheckedExtT, setIsCheckedExtT] = useState(false);
    const [isCheckedExtB, setIsCheckedExtB] = useState(false);
    const [isCheckedEurT, setIsCheckedEurT] = useState(false);
    const [isCheckedEurB, setIsCheckedEurB] = useState(false);
    const [isCheckedBrT, setIsCheckedBrT] = useState(false);
    const [isCheckedBrB, setIsCheckedBrB] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selection, setSelection] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      });

    const clientePorto = clienteId === 25985 ? true : false;

    const { format } = require('date-fns');

    let dataHoje = new Date();
    let dataFinal = new Date();
    let dataMax = new Date();
    let dataMin = new Date();
    
    // Formatar a data
    dataMin = formatDate(dataMin);
    
    // Adicionando 210 dias à data atual
    dataMax.setDate(dataMax.getDate() + 210);
    
    // Adicionando 60 dias à data atual
    dataFinal.setDate(dataFinal.getDate() + 90);
    
    // Formate a data para exibir apenas dia e mês
    dataHoje = format(dataHoje, 'dd/MM');
    dataFinal = format(dataFinal, 'dd/MM');
    
    dataMax = formatDate(dataMax);

    const handleConfirmDate = () => {
    
        // Fecha o modal
        setModalOpen(false);
        setErrorVigencia(false);

        let dataIni = selection.startDate;
        let dataFim = selection.endDate;

        localStorage.setItem('inicioVigencia', dataIni.toISOString());
        localStorage.setItem('fimVigencia', dataFim.toISOString());

        dataIni = format(dataIni, 'dd/MM');
        dataFim = format(dataFim, 'dd/MM');

        setStartDate(dataIni);
        setEndDate(dataFim);

        const dias = generateDateRange(selection.startDate, selection.endDate);
        if(vigenciaMaxima < dias) {
            setErrorVigencia(true);
            setMessagemVigencia('Os dias selecionados é maior que a vigencia máxima contratada');
            setStartDate('00/00');
            setEndDate('00/00');
            setHabilitado(true);
        } else {
            setHabilitado(false)
        }
    };

    const [startDate, setStartDate] = useState('00/00');
    const [endDate, setEndDate] = useState('00/00');

    // Função para gerar o intervalo de datas
    function generateDateRange(startDate, endDate) {
        const differenceInTime = endDate.getTime() - startDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return Math.round(differenceInDays);
    }

    const [destino, setDestino] = useState('Selecionar para onde vai');

    const handleClickExterior = () => {
        setIsCheckedExtT('#fff');
        setIsCheckedExtB('#00CC74');
        setDestino("Exterior exceto Europa");

        setIsCheckedEurT('#000');
        setIsCheckedBrT('#000');
        setIsCheckedEurB('#fff');
        setIsCheckedBrB('#fff');

    };

    const handleClickEuropa = () => {
        setIsCheckedEurT('#fff');
        setIsCheckedEurB('#00CC74');
        setDestino("Europa");

        setIsCheckedExtT('#000');
        setIsCheckedBrT('#000');
        setIsCheckedExtB('#fff');
        setIsCheckedBrB('#fff');
    };

    const handleClickBrasil = () => {
        setIsCheckedBrT('#fff');
        setIsCheckedBrB('#00CC74');
        setDestino("Brasil");

        setIsCheckedExtT('#000');
        setIsCheckedEurT('#000');
        setIsCheckedExtB('#fff');
        setIsCheckedEurB('#fff');
    };
    
    localStorage.setItem([`destino_${titular.key}`], destino);

    const handleSelectChange = (event) => {
        setDestino(event.target.value);
    };

    const openModalDestino = () => {
        setIsModalDestino(true);
    };
    
    const closeModalDestino = () => {
        setIsModalDestino(false);
    };

    return (
        <>
        
        <div className="card-pais" key={quantidade}>
            <div className="local-date">
                <div className="item-right padding-top padding-left">
                    <div className="icon-and-text">
                    <label className="titulo-destino">Para onde</label>
                    <div className="grid-two-columns-left">
                        <div className="icon">
                            <img
                                className="icon"
                                src={Place}
                                alt="Place"
                                width={24}
                                height={24}
                            />
                        </div>
                        {clientePorto ?
                            <div onClick={openModalDestino} className="subtitulo-destino">{destino}</div>
                        :
                        <div>
                            <ListDestino
                                className="subtitulo-destino"
                                dataFrom={{handleSelectChange}}
                                dataFromDes={{destino}}
                                formData={{formData}}
                                titular={{titular}}
                                handleChange={{handleChange}}
                            />
                        </div>
                        }
                    </div>
                    </div>
                </div>

                <div className="vertical-divider"></div>

                <div className="item-right padding-top padding-mobile">
                    <div className="icon-and-text">
                        <label className="titulo-destino">Embarque e Desembarque no Brasil</label>
                        <div className="grid-two-columns-left">
                            <div className="icon">
                                <img
                                    className="icon"
                                    src={Calendar}
                                    alt="Place"
                                    width={24}
                                    height={24}
                                />
                            </div>
                            <div className="subtitulo-destino">
                            <span onClick={() => setModalOpen(true)}>De {startDate} a {endDate}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='delete-destino' onClick={() => onRemove(quantidade)}>
                {quantidade > 0 ?
                    <img
                        className="icon"
                        src={Delete}
                        alt="Place"
                        width={30}
                        height={30}
                    />
                    :
                    <></>
                }
            </div>
        </div>
        {errorVigencia && (
        <div>
            <h4 className="alerta-error">{messagemVigencia}</h4>
        </div>
        )}

        {modalOpen && (
            <div className="modal-data">
                <div className="modal-content-data centralizar-date-range">
                    <span className="close-data" onClick={() => setModalOpen(false)}>&times;</span>
                    <p className='titulo-daterange'>SELECIONE A DATA</p>
                    
                    <DateRange 
                        minDate={new Date(dataMin)}
                        maxDate={new Date(dataMax)}
                        locale={ptBR}
                        ranges={[selection]}
                        onChange={(range) => setSelection(range.selection)}
                        className="custom-date-range"
                    />
                    <div className='grid-right'>
                        <span className='cancel-date-range' onClick={() => setModalOpen(false)}>CANCEL</span>
                        <span className='ok-date-range' onClick={handleConfirmDate}>OK</span>
                    </div>
                </div>
            </div>
        )}
        
        <Modal
            isOpen={isModalDestino}
            onRequestClose={closeModalDestino}
            className="modal"
            overlayClassName="modal-overlay"
        >
            <h2 className="modal-title">Selecione os outros destinos</h2>
            <div 
                className="button-radio" 
                style={{ backgroundColor: isCheckedExtB }}
                onClick={handleClickExterior}
            >
                <div className="grid-two-columns-left">
                    <img
                        src={Place}
                        alt="Place"
                        width={24}
                        height={24}
                    />
                    <span className="title-button" style={{color: isCheckedExtT}}>Exterior exceto Europa</span>
                </div>
            </div>
            <div 
                className="button-radio" 
                style={{ backgroundColor: isCheckedEurB }}
                onClick={handleClickEuropa}
            >
                <div className="grid-two-columns-left">
                    <img
                        src={Place}
                        alt="Place"
                        width={24}
                        height={24}
                    />
                    <span className="title-button" style={{color: isCheckedEurT}}>Europa</span>
                </div>
            </div>
            <div 
                className="button-radio" 
                style={{ backgroundColor: isCheckedBrB }}
                onClick={handleClickBrasil}
            >
                <div className="grid-two-columns-left">
                    <img
                        style={{color: isCheckedBrT}}
                        src={Place}
                        alt="Place"
                        width={24}
                        height={24}
                    />
                    <span className="title-button" style={{color: isCheckedBrT}}>Brasil</span>
                </div>
            </div>
            <button className="modal-button" onClick={closeModalDestino}>
                Continuar
            </button>
        </Modal>
        </>
    );
};

function formatDate(date) {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
  
    // Adiciona um zero à esquerda se for menor que 10
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
  
    return year + '-' + month + '-' + day;
}