// src/components/Home.tsx
"use client"
import axios from 'axios';

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import './style.css';
import LogoColor from '../LogoColor.svg';
import LogoBranco from '../LogoBranco.svg';

// Esquema de validação usando Yup
const validationSchema = Yup.object({
  //cpf: Yup.string().required('CPF ou Carteirinha é obrigatório'),
});

function formatCPF(cpf) {
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  
  return cpf;
}


export default function Card() {
  const navigate = useNavigate();
  const [cpf, setCpf] = useState('');
  const [buttonColor, setButtonColor] = useState('');
  const [exibirAlert, setExibirAlert] = useState(false);

  const larguraTela = window.innerWidth;

  let logoSVG = LogoBranco;
  if (larguraTela >= 768) {
    logoSVG = LogoColor;
  }

  const params = new URLSearchParams(window.location.search);

  const handleMouseOver = () => {
    setButtonColor('#b81482');
  };
  
  const handleMouseOut = () => {
    setButtonColor('#E224A9');
  };

  const handleChange = (e) => {
    let inputCpf = e.target.value;
    
    if (inputCpf.length < 15) {
      setCpf(formatCPF(inputCpf));
    } else {
      inputCpf = inputCpf.replace(/[.-]/g, '');
      setCpf(inputCpf);
    }
  };

  const formik = useFormik({
    initialValues: {
      cpf: ''
    },
    validationSchema: validationSchema,
    onSubmit: () => {

      const captas = params.get('capta');
      const planoids = params.get('planoid');

      const cleanCpf = cpf.replace(/[.-]/g,'');
      
     getDataPassageiro(cleanCpf, captas, planoids);
      
    },
  });

  const getDataPassageiro = async(cpf, capta, planoid) => {
    try {
      const response = await axios.get(
        `https://h-corisapi-autoservico.azurewebsites.net/api/v1/Assegurados/GetByCpfCarteirinhaPlano/${cpf}/${capta}/${planoid}`
        );

        if(response.status === 200) {
          navigate('/dados-cliente', { state: {passageiros: response.data}});
        } else {
          setExibirAlert(true);
        }
    } catch (error) {
      setExibirAlert(true);
    }
  }

  useEffect(() => {
   
  }, [])

  return (
    <div className="borda-card-home rounded-xl p-12">
      <img
        className="logo-home"
        src={logoSVG}
        alt="Logo"
      />
      <h2 className='titulo-home'>Certificado de viagem</h2>
      <p className='descricao'>Digite o seu número do CPF ou da carteirinha para emitir o seu certificado</p>
       
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <TextField
              id="cpf"
              variant="outlined"
              name="cpf"
              label="CPF ou Carteirinha"
              style={{ width: '280px' }}
              value={cpf}
              maxLength={14}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cpf && Boolean(formik.errors.cpf)}
              helperText={formik.touched.cpf && formik.errors.cpf}
            />
          </div>
          <button 
          style={{ backgroundColor: buttonColor }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          type="submit" className='buton-enviar botao'>
            <p className='text-button'>Entrar</p>
          </button>
          {exibirAlert ?
          <><div>
              <label className="alert-home">Cadastro não encontrado!</label>
            </div><div>
                <label className="contato-home">Por favor, contate-nos pelo e-mail: certificados@assist24h.com.br</label>
              </div>
            </>
            :
            <p></p>
            }
        </form>
      </div>
    </div>
  );
};