import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Cadastro from './components/Cadastro';
import Sucesso from './components/Sucesso';

import './App.css';

function App() {
  return (
    <Router>
        <Routes>
          <Route path='/' element={
            <div className="App App-header">
            <Home />
            </div>
            }>
          </Route>
          <Route path='/dados-cliente' element={
            <Cadastro />
            }
          />
          <Route path='/emissao-sucesso' element={
            <Sucesso />
            }
          />
        </Routes>
    </Router>
  );
}

export default App;
