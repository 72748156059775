// src/components/Card.tsx
"use client"
import axios from 'axios';

import React, {useState} from 'react';
import Form from '../Form';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoBranco from '../LogoBranco.svg';
import LogoColor from '../LogoColor.svg';
import * as Yup from 'yup';

import Passageiro from '../Form/Passageiro';
import './style.css';

// Esquema de validação usando Yup
const validationSchema = Yup.object({
    nome: Yup.string().required('O nome é obrigatório'),
    email: Yup.string().email('Formato de e-mail inválido').required('O e-mail é obrigatório'),
    confirmar_email: Yup.string().email('Formato de e-mail inválido').required('O e-mail da confirmação é obrigatório'),
    cpf: Yup.string().required('CPF é obrigatório'),
    telefone: Yup.string().required('Telefone é obrigatório'),
    sexo: Yup.string().required('Sexo é obrigatório'),
    cep: Yup.string().required('CEP é obrigatório'),
    endereco: Yup.string().required('Endereço é obrigatório'),
    numero: Yup.string().required('Número é obrigatório'),
    bairro: Yup.string().required('Bairro é obrigatório'),
    cidade: Yup.string().required('Cidade é obrigatória'),
    uf: Yup.string().required('Estado é obrigatório'),
    destino: Yup.string().required('Destino é obrigatório')
});

export default function Cadastro() {
    const navigate = useNavigate();
    const [buttonColor, setButtonColor] = useState('');
    const [habilitado, setHabilitado] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const params = new URLSearchParams(window.location.search);

    const { state } = useLocation();

    const listPassageiro = state.passageiros.data;
    listPassageiro.forEach((value, key) => {
        const sexo = value.sexo;
        value.sexo = sexo.trim();

        const addreess = value.endereco;
        
        const ultimoEspaco = addreess.lastIndexOf(" ");
        const endereco = addreess.substring(0, ultimoEspaco);
        const numero = addreess.substring(ultimoEspaco + 1);
        value.addreess = endereco;
        value.numero = numero;
        value.key = key++;
        value.dependente = listPassageiro.length - 1;
    });
    
    const titular = [listPassageiro[0]];
    titular[0].title = 'Passageiro 1';
    titular[0].dados = 'Dados pessoais (TITULAR)';

    const vigencia = titular[0].plano.vigenciaMaxima;
    const multiviagens = titular[0].plano.multiviagens;
    localStorage.setItem("vigencia", vigencia);
    localStorage.setItem("multiviagens", multiviagens);
    
    const larguraTela = window.innerWidth;
    
    let logoSVG = LogoColor;
    if (larguraTela >= 768) {
        logoSVG = LogoBranco;
    }
    
    const dependentes = listPassageiro.slice(1);
    
    dependentes.forEach((value, key) => {
        value.id = key + 1;
        value.title = "Passageiro " + key + 1;
        value.dados = "Dados pessoais";
    });

    const handleMouseOver = () => {
        setButtonColor('#E3D93D');
    };

    const handleMouseOut = () => {
        setButtonColor('#FFE603');
    };

    const fullPassageiro = [...titular, ...dependentes];
    
    const [formData, setFormData] = useState(() => {
        const initialFieldDataArray = [];

        // Itera sobre cada passageiro para criar um objeto de dados
        fullPassageiro.forEach((item, index) => {
            const destino = localStorage.getItem([`destino_${index}`]);

            const fieldData = {
                documento: item.documento,
                nome: `${item.nome} ${item.sobrenome}`,
                sobrenome: item.sobrenome,
                dtNascimento: item.dataNascimento,
                email: item.email,
                confirmar_email: item.email,
                fone: '',
                cep: item.cep,
                endereco: item.addreess,
                numero: item.numero,
                cidade: item.cidade,
                uf: item.uf,
                sexo: item.sexo,
                clienteid: item.clienteId,
                plano: item.plano.nPlano,
                destino: destino,
                whatsapp: '',
                nPlano: `${item.plano.nPlano}`
            };

            // Adiciona o objeto ao array
            initialFieldDataArray.push(fieldData);
        });
    
        return initialFieldDataArray;
    });

    const handleChange = (event, index) => {
        const { name, value } = event.target;
      
        // Atualiza o estado com os novos valores
        setFormData(prevFormData => prevFormData.map((item, i) => 
          i === index ? { ...item, [name]: value } : item
        ));
      };

    const handleSubmit = async (event) => {

        event.preventDefault();
        const planoId = params.get('planoid');
        const emissor = params.get('emissor');  
        const emissorLogin = params.get('emissorlogin');
        const agencia = params.get('agencia');

        formData.forEach((item, key) => (
            item.destino = localStorage.getItem([`destino_${key}`]),
            item.inicioVigencia = localStorage.getItem([`inicioVigencia`]),
            item.fimVigencia = localStorage.getItem([`fimVigencia`]),
            item.dias = localStorage.getItem([`vigencia`]),
            item.planoId = planoId,
            item.emissor = emissor,
            item.emissorLogin = emissorLogin,
            item.agencia = agencia,
            item.whatsapp = item.fone,
            item.sobrenome = item.nome // TODO: Tratar Sobrenome
        ));

        try {
            formData.forEach(async (payload, key) => {
                let nascimento = payload.dtNascimento;
                const [day, month, year] = nascimento.split('/');
        
                const date = new Date(`${year}-${month}-${day}`);
                payload.dtNascimento = date.toISOString();

                const response = await axios.post(
                    'https://h-corisapi-autoservico.azurewebsites.net/api/v1/Voucher/GeraVoucher',
                    payload,
                    {
                      headers: {
                        'Content-Type': 'application/json'
                      }
                    }
                );

                if (response.code === 200) {
                    console.log(response);
                    navigate('/emissao-sucesso');
                }
           
            })
          } catch (error) {
            console.error('Error da emissão:', error);
          }

    };
      
    return (
        <div className="container">
            <header className="cabecalho header">
            <img
                className="logo logo-cad"
                src={logoSVG}
                alt="Logo Coris"
                width={180}
                height={37}
            />
            </header>
            <form onSubmit={handleSubmit}>
            <div className="corpo conteudo-cad body padding-top">
                <div className="bg-card borda-card-cad rounded-xl p-12">
                    <h2 className='titulo'>Dados para emissão</h2>
                    <Form titular={titular} validationSchema={validationSchema} formData={formData} handleChange={handleChange} setIsOpen={setIsOpen} setHabilitado={setHabilitado} />
                </div>
            </div>
            <div className="corpo conteudo-cad body">
                <Passageiro 
                    passageiros={dependentes} 
                    validationSchema={validationSchema} 
                    formData={formData} 
                    handleChange={handleChange} 
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setHabilitado={setHabilitado}
                />
            </div>
            <div className="corpo conteudo-cad body">
                <button 
                    className="bottom-final botao centralizar"
                    type='submit'
                    disabled={habilitado}
                    style={{ backgroundColor: buttonColor }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}>
                    <span className='textFinal'>
                    Finalizar
                    </span>
                </button>
            </div>
            </form>
        </div>
    );
};